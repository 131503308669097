@import "../abstracts/abstracts-dir.scss";

.left-menu {
  z-index: 4;
  //   max-width: 240px;
  width: 100%;
  //   height: calc(100vh - 73px);
  left: 0;

  background-image: linear-gradient(90.31deg, #9ee7f8 0.04%, #ee90d4 100%);
  //   box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.1);
  ul {
    display: flex;
    // margin-left: 30px;
    // margin-right: 30px;
    justify-content: space-between;
  }
  .sidebar-menu {
    display: flex;

    .sidebar-menu-desc {
      color: $primary-color;
      opacity: 0.7;
      font-size: fontSize(16px);
      font-family: "Poppins";
    }
  }
  .nav-link {
    padding: 8px 27px 6px 27px;
    min-height: 48px;
    display: flex;
    align-items: center;
    transition: all 200ms ease-in-out;
    cursor: pointer;

    &:hover,
    &.active {
      .sidebar-menu-desc {
        font-family: "Poppins";
        font-weight: 600;
      }
    }
  }
  .images {
    position: absolute;
    margin-left: -0.9cm;
    //  z-index: 100;
    margin-top: -0.4cm;
    padding: 7px;
  }
  .rightside-container {
    margin-left: 200px;
  }
  .sidemenu-overlay {
    display: none;
  }
  // @media (max-width: 1199.98px) {
  //   .left-menu {
  //     min-height: initial;
  //     overflow-y: auto;
  //     transform: translate(-100%, 0);
  //     transition: all 0.3s ease;
  //     &.open {
  //       transform: translate(0, 0);
  //     }
  //   }
  //   .rightside-container {
  //     margin-left: 0;
  //   }
  //   .sidemenu-overlay {
  //     display: block;
  //     top: 66px;
  //     left: 0;
  //     bottom: 0;
  //     width: 100%;
  //     background-color: rgba(0, 0, 0, 0.2);
  //     position: fixed;
  //     z-index: 3;
  //     visibility: hidden;
  //     opacity: 0;
  //     transition: all 0.3s ease;
  //     &.open {
  //       visibility: visible;
  //       opacity: 1;
  //     }
  //   }
  // }
}
