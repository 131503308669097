html,
body {
  font-family: "poppins", "sans-serif";
  line-height: 1.5;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  line-height: 24px;
}

h1,
.h1 {
  font-size: fontSize(36px);
  font-weight: 600;
}

h2,
.h2 {
  font-size: fontSize(30px);
}

h3,
.h3 {
  font-size: fontSize(24px);
}

h4,
.h4 {
  font-size: fontSize(18px);
}

h5,
.h5 {
  font-size: fontSize(14px);
}

h6,
.h6 {
  font-size: fontSize(12px);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.fs-90 {
  font-size: fontSize(90px);
}
