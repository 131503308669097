@import "assets/scss/abstracts/abstracts-dir.scss";

.create-PO {

  .PO-content {
    border: 1px solid $primary-color;
    padding: 24px 31px;
    margin-top: 50px;
  }

  ul {
    display: flex;
    li {
      margin-right: 41px;
    }
    // justify-content: space-between;
    a {
      padding: 0 0 1.3rem 0;
    }
  }

  .action-img{
    cursor: pointer;
  }

  .btn {
    font-family: Muli-Regular;
  }
  .copy-btn {
    margin-top: 200px;
  }
  .icon-calendar {
    color: #b2b2b2;
  }
  .input-group {
    input,
    textarea {
      font-size: fontSize(14px);
      font-family: 'Montserrat', sans-serif;
      font-weight: normal;
    }
  }

  .input-group-normal {
    input,
    textarea {
      font-size: fontSize(14px);
      font-family: 'Montserrat', sans-serif;
      font-weight: normal;
      background: #f9f9f9;
      border: 0.25px solid #c0c0c0;
      height: 50px;
    }
  }

  

  .input-group-desc {
    input,
    textarea {
      font-size: fontSize(14px);
      font-family: 'Montserrat', sans-serif;
      font-weight: normal;
      background: #f9f9f9;
      border: 0.25px solid #c0c0c0;
      height: 150px;
    }
  }

  

  .input-group-address {
    input,
    textarea {
      font-size: fontSize(14px);
      font-family: 'Montserrat', sans-serif;
      font-weight: normal;
      background: #f9f9f9;
      border: 0.25px solid #c0c0c0;
      height: 40px;
    }
  }

  .input-group-discount {
    input,
    textarea {
      font-size: fontSize(14px);
      font-family: 'Montserrat', sans-serif;
      font-weight: normal;
      background: #f9f9f900;
      border: 0.25px solid #c0c0c0b3;
      height: 40px;
    }
  }

  .input-group-details {
    input,
    textarea {
      font-size: fontSize(14px);
      font-family: 'Montserrat', sans-serif;
      font-weight: normal;
      background: #f9f9f9;
      border: 0.25px solid #c0c0c0;
      height: 40px;
    }
  }
  
  .sidebar-menu {
    display: flex;
    &.active {
      .sidebar-menu-desc {
        color: $white;
        font-family: "Poppins";
        background: $primary-color;
        border-radius: 4px;
        border-color: $primary-color;
      }
    }
    .sidebar-menu-desc {
      color: #666666;
      font-size: fontSize(14px);
      font-family: "Poppins";
      padding: 7px;
      margin: 2px;
    }
  }
      
}
