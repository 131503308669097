@import "assets/scss/abstracts/abstracts-dir.scss";

.header {
  width: 100%;
  // box-shadow: 3px 0 10px 0 rgba(56, 56, 56, 0.2);
  // box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.18);
  z-index: 2;
  padding: 0;
  margin: 0;
  .site-header {
    font-family: "Poppins", sans-serif;
    //display: flex;
    // background: $primary-color;

    .navbar-brand {
      flex: 1;
      .navbar-logo {
        img {
          height: 40px;
        }
      }
      .navbar-logo .icon-logo {
        padding-left: 30px;
      }
    }

    #navbar-menu {
      //display: flex;
      // flex: 1;
      .navbar-nav {
        align-items: center;
        align-self: center;
        //flex-direction: row;
        // .dashboard-heading {
        //   font-size: fontSize(18px);
        //   line-height: 27px;
        //   text-transform: uppercase;
        //   color: $white;
        // }
        // .border-right {
        //   border-right: 1px solid $white;
        //   height:30px
        // }
        .bell-icon {
          position: relative;
          top: 6px;
          i {
            font-size: 20px;
          }
        }
        .bell-notify {
          position: absolute;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          top: 4px;
          right: 13px;
          background: $primaryalt-color;
          border: 1.6px solid $white;
        }
        .profile-icon {
          .userProfile {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
          i {
            font-size: 5px;
            padding-left: 20px;
          }
        }
        > li {
          padding: 0px 15px;
          //z-index: 3;
          position: relative;
          cursor: pointer;
        }
      }
    }
  }
  .tab-menu {
    ul {
      display: flex;
      margin-left: 30px;
      margin-right: 30px;
      li {
        padding: 12px 15px 18px 15px;
        color: #666666;
        font-size: fontSize(18px);
        font-family: Muli-Medium;
      }
      li:active,
      li:hover,
      li:visited {
        border-bottom: 5px solid $primary-color;
        color: $primary-color;
      }
    }
  }
}
