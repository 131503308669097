@import "../abstracts/abstracts-dir.scss";

.table-header {
  .table-type {
    font-weight: bold;
    text-decoration: underline;
  }
  .table-total {
    margin-left: 30px;
    font-size: fontSize(15px);
  }
}

.maintable {
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 2.5px -0.8px #777;
  -moz-box-shadow: 0 0 2.5px -0.8px #777;
  box-shadow: 0 0 2.5px -0.8px #777;
  overflow: auto;
  .maintable-content {
    border-radius: 6px;

    // .table-striped > tbody > tr:nth-child(even) > td {
    //   background-color: #dedede;
    // }
    // .table-striped > tbody > tr:nth-child(odd) > td {
    //   background-color: $white;
    // }
  }
  .table {
    margin-bottom: 0;
    th {
      font-weight: normal;
      background: $primary-color;
      color: $white;
      font-size: fontSize(14px);
      padding-left: 0;
      padding-right: 0;
    }
    td {
      color: $gray;
      font-size: fontSize(14px);
    }

    // .table-left-arrow {
    //   transform: rotateY(180deg);
    // }
    // .table-striped > tbody > tr:nth-child(even) > td,
    // .table-striped > tbody > tr:nth-child(odd) > th {
    //   background-color: #e5e5e5;
    // }
  }
}

.normal-table {
  .maintable {
    overflow: auto;
    .maintable-content {
      border-radius: 0px;
      border: 1px solid #dcdcdc;
    }
    tr {
      font-family: "Poppins", sans-serif;
      font-size: fontSize(12px);
      td {
        padding: 20px 0px;
      }
    }
    th {
      background: #f2f2f2;
      color: $black;
      font-size: fontSize(12px);
    }
    tr:nth-child(odd) {
      background-color: $white;
    }
    tr:nth-child(even) {
      background-color: #dedede;
    }
  }
}

// table pagination
.table-pagination {
  .list-page {
    font-size: 12px;
    .previous-button {
      // font-size: 12px;
      background-color: $white;
      border: none;
      margin: 5px;
      color: $black;
      opacity: 0.6;
    }
    .disable {
      opacity: 0.2 !important;
      cursor: no-drop;
    }
    .active {
      background: $primary-color !important;
      color: $white !important;
    }
    .page-number {
      margin: 5px;
      width: 23px;
      height: 26px;
      background-color: $white;
      color: $black;
      opacity: 0.6;
    }
    .next-button {
      background-color: $white;
      border: none;
      margin: 5px;
      color: $black;
      opacity: 0.6;
    }
  }
}
