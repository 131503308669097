@font-face {
  font-family: Muli-Regular;
  src: url("../../fonts/Muli/Muli-Regular.ttf");
}
@font-face {
  font-family: Muli-Medium;
  src: url("../../fonts/Muli/Muli-Medium.ttf");
}
@font-face {
  font-family: Muli-ExtraBold;
  src: url("../../fonts/Muli/Muli-ExtraBold.ttf");
}
@font-face {
  font-family: Muli-Bold;
  src: url("../../fonts/Muli/Muli-Bold.ttf");
}
@font-face {
  font-family: Muli-Light;
  src: url("../../fonts/Muli/Muli-Light.ttf");
}
@font-face {
  font-family: Muli-Italic;
  src: url("../../fonts/Muli/Muli-Italic.ttf");
}
@font-face {
  font-family: Muli-SemiBold;
  src: url("../../fonts/Muli/Muli-SemiBold.ttf");
}
@font-face {
  font-family: Muli-Black;
  src: url("../../fonts/Muli/Muli-Black.ttf");
}
