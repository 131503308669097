@import "../abstracts/abstracts-dir.scss";
.error-message {
  font-size: fontSize(12px);
  color: red;
  padding-top: 7px;
  width: 100%;
}

.notification-container {
  top: initial;
  bottom: 20px;
  .notification-success {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  }
}

.notification {
  background-color: $drk-blue;
  color: $white !important;
  border-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  margin: 10px 0;
}
